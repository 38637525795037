.subCategoryItem {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease, transition-delay 0.3s ease;
}

.subCategoryItem.visible {
  opacity: 1;
  transform: translateY(0);
}
